import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bridged-resource"
    }}>{`Bridged Resource`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchbridgedresource"
    }}>{`fetchBridgedResource`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* bridgedResourceName -> string = (required) name of the bridged resource
* formSlug -> string = (required) slug of the form that contains the bridged resource
* kappSlug -> string = (required) slug of the kapp that contains the form with the bridged resource
* values -> { [string]: any } = map of field names to values that will be set available to the bridged resource
* attributes -> string[] = list of attributes the bridged resource should return; returns all if not specified
* metadata -> { [string]: any } = map of metadata properites to provide the bridge adapter; see specific adapter for valid options
`}</code></pre>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* records -> Record[] = an array of records; returned if the bridge qualification used has a type of Multiple
* record -> Record = a single record; returned if the bridge qualification used has a type of Single
* error -> ErrorResponse{} = an error object on failure
* metadata -> object = map of metadata properties returned by the bridge adapter; see specific adapter for returned values
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchBridgedResource } from '@kineticdata/react';

// Multiple results bridge model
fetchBridgedResource({
  bridgedResourceName: 'All Records',
  formSlug,
  kappSlug,
}).then(({ records }) => console.log(records));

// Single result bridge model
const values = { Id: 'allan.allbrook' };
fetchBridgedResource({
  bridgedResourceName: 'Record By Id',
  formSlug,
  kappSlug,
  values,
}).then(({ record }) => console.log(record));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      